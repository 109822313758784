import styles from '../../styles/NavBar.module.scss'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import { Badge, CircularProgress, InputAdornment, InputBase, Stack, Tooltip, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import { grey } from '@mui/material/colors'
import { styled, alpha } from '@mui/material/styles'
import NotificationsIcon from '@mui/icons-material/Notifications'
import Avatar from '@mui/material/Avatar'
import restAPI from '../../services/rest-api'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { IShareSession } from '../../types/IShareSession'
import Divider from '@mui/material/Divider'
import { useTranslation } from 'react-i18next'
import { MouseEvent } from 'react'
import UserSettings from 'components/UserSettings/UserSettings'
import theme from 'theme'
import NotificationDialog from 'components/Home/NotificationDialog'
import UserAvatar from './AvatarObject'
import API from '../../services/rest-api'
import SearchResultDialog from 'components/Home/SearchResultDialog'
import config from 'services/config'
import { capitalizeFirstLetter } from '../../services/stringUtils'
import { useNavigate } from 'react-router-dom'
import useSession from 'hooks/useSession'
import { ProjectInformationContext } from 'context/ProjectInformationContext'

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    backgroundColor: '#ffffff',
    borderRadius: theme.shape.borderRadius,
    color: alpha('#000', 0.6),
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(0.5)})`,
        width: '100%',
    },
}))

export default function NavBar() {
    // URL
    const { component, id } = useParams<any>()

    const location = useLocation()
    const navigate = useNavigate()
    const { projectInformation } = useContext(ProjectInformationContext);

    // Translation
    const { t } = useTranslation('common')
    const navbarTitle = component ? t(`NavBar.${capitalizeFirstLetter(component)}`) : component

    // Search
    const [showSearchBar, setShowSearchBar] = useState(false)
    const searchBarRef = useRef<any>()
    const [searchResults, setSearchResults] = useState(null)
    const searchBoxRef = useRef<any>()
    const [typingTimer, setTypingTimer] = useState<NodeJS.Timeout>(null)
    const [searchBoxOpen, setSearchBoxOpen] = useState(false)
    // Notification
    const [amtNotifs] = useState(5)
    const [showNotifications, setShowNotifications] = useState(false)
    const [notificationAnchorEl, setNotificationAnchorEl] = useState<null | HTMLElement>(null)
    // User settings
    const [usersettingsAnchorEl, setUserSettingsAnchorEl] = useState<null | HTMLElement>(null)
    const openUserMenu = Boolean(usersettingsAnchorEl)
    const [openUserSettings, setOpenUserSettings] = useState(false)
    const [, setAvatar] = useState<UserAvatar | undefined>(undefined)
    // Session
    const [session, setUISession] = useState<IShareSession | null>(null)
    const { isGuest, logout, getSession } = useSession()
    const logoutUI = () => {
        setUISession(null)
        setAvatar(undefined)
        logout()
    }

    // --- UseEffect ---
    //Mounting
    useEffect(() => {
        const getAvatar = async () => {
            try {
                let currentSession = getSession()
                if (!currentSession) {
                    return
                }
                if (!currentSession.avatar) {
                    if (!isGuest()) {
                        // If not guest fetch
                        const response = await restAPI.getUserAvatar()
                        if (response.data?.avatar) {
                            currentSession.avatar = response.data.avatar
                            currentSession.avatar = {
                                letter: currentSession?.name?.slice(0, 1)?.toUpperCase() ?? '',
                                URI: response.data.avatar.URI,
                                color: response.data.avatar.backgroundColor,
                            }
                        }
                    }
                    if (!currentSession.avatar) {
                        currentSession.avatar = {
                            letter: currentSession?.name?.slice(0, 1)?.toUpperCase() ?? '',
                            URI: undefined,
                            color: 'hsl(' + Math.random() * 359 + ',70%,68%)',
                        }
                    }
                    if (!isGuest()) restAPI.setUserAvatar(currentSession.avatar.URI, currentSession.avatar.color)
                }
                setUISession(currentSession)
            } catch (err) {
                console.error('Navigation bar: Could not fetch avatar!')
            }
        }
        getAvatar()
    })

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        let settings = queryParams.get('settings')
        if (settings === 'general' || settings === 'preferences' || settings === 'notifications') {
            setOpenUserSettings(true)
        }
    }, [location.search, id])

    // --- User Settings ---
    const handleUserSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
        setUserSettingsAnchorEl(event.currentTarget)
    }
    const handleUserSettingsClose = () => {
        setUserSettingsAnchorEl(null)
    }
    const closeUserSettings = () => {
        setOpenUserSettings(false)
    }
    const userSettingsToggle = () => {
        setOpenUserSettings(!openUserSettings)
    }

    // --- Search Bar ---
    const handleSearchClick = (event: MouseEvent<HTMLElement | SVGSVGElement> | null) => {
        event?.preventDefault()

        setShowSearchBar(!showSearchBar)
        if (!showSearchBar)
            // Will open
            searchBarRef.current?.firstChild.focus()
        else {
            setSearchBoxOpen(false)
            setSearchResults(null)
            if (typingTimer) {
                clearTimeout(typingTimer)
                setTypingTimer(null)
            }
            searchBarRef.current.firstChild.value = ''
        }
    }
    const handleSearchInput = async (data: any) => {
        let searchValue = data?.target?.value
        if (!searchValue || searchValue === '') {
            setSearchBoxOpen(false)
            clearTimeout(typingTimer)
            setTypingTimer(null)
            setSearchResults(null)
            return
        } else if (!searchBoxOpen) setSearchBoxOpen(true)

        if (typingTimer) {
            clearTimeout(typingTimer)
        }

        setTypingTimer(
            setTimeout(() => {
                handleSearch(null)
            }, 500)
        )
    }

    const handleSearch = (event: any) => {
        let searchValue = searchBarRef.current.firstChild.value
        if (!searchValue || searchValue === '') {
            setSearchBoxOpen(false)
            clearTimeout(typingTimer)
            setTypingTimer(null)
            setSearchResults(null)
            return
        }
        API.performSearchQuery(searchValue, 6, 0)
            .then((results) => {
                try {
                    if (!results) setSearchResults([])
                    else setSearchResults(results)
                    setTypingTimer(null)
                } catch (err) {
                    console.error(err)
                }
            })
            .catch((err) => {
                console.error(err)
                setTypingTimer(null)
            })
    }

    const handleSearchBlur = (event: any) => {
        let related = event.relatedTarget as Node
        if (!related || !searchBoxRef.current.contains(related)) {
            setSearchBoxOpen(false)
            let searchValue = searchBarRef.current.firstChild.value
            let relTargetId = event.relatedTarget?.id
            if ((!searchValue || searchValue === '') && showSearchBar && relTargetId !== 'searchIcon') {
                setShowSearchBar(false)
            }
        }
    }

    const handleSearchFocus = (event: any) => {
        let searchValue = searchBarRef.current.firstChild.value
        if (searchValue && searchValue !== '') setSearchBoxOpen(true)
    }



    // --- Output ---
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar sx={{ flexGrow: 1, background: 'linear-gradient(90deg, #f6a81c 0%, #fdc010 100%)' }} position="sticky">
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0}>
                    <Box className={styles.clip}>
                        <Link to="/">
                            <img title="Go to homepage" alt="link to homepage" src="/img/hsbShareLogo.svg" height={"24px"} />
                        </Link>
                    </Box>
                    <Box sx={{ flexGrow: 8 }}>
                        <Typography className={styles.show} sx={{ color: 'white', fontSize: '1.2rem' }}>
                            {projectInformation?.name ?? navbarTitle ?? t('UserSettings.Home')}
                        </Typography>
                    </Box>
                    <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ paddingRight: 1 }} spacing={1}>
                        <Box className={styles['userFunctionality']}>
                            {!isGuest() && (
                                <Box ref={searchBoxRef} className={styles.show}>
                                    <div style={{ width: showSearchBar ? '555px' : 0, transition: 'width 1s', overflow: 'hidden' }}>
                                        <StyledInputBase
                                            id={'inputbase'}
                                            ref={searchBarRef}
                                            sx={{ width: '100%' }}
                                            placeholder={t('NavBar.Search')}
                                            inputProps={{ 'aria-label': 'search' }}
                                            onChange={handleSearchInput}
                                            onSubmit={handleSearch}
                                            onBlur={handleSearchBlur}
                                            onFocus={handleSearchFocus}
                                            autoComplete="off"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    {typingTimer && (
                                                        <CircularProgress style={{ marginRight: '5px' }} color={'secondary'} size={24} />
                                                    )}
                                                </InputAdornment>
                                            }
                                        />
                                    </div>

                                    <SearchResultDialog
                                        open={searchBoxOpen}
                                        onClose={() => {
                                            setSearchBoxOpen(false)
                                        }}
                                        results={searchResults}
                                        anchor={searchBoxRef.current}
                                    />
                                </Box>
                            )}
                            {!isGuest() && (
                                <Box>
                                    <Tooltip title={t('NavBar.Search')?.toString()}>
                                        <IconButton id="searchIcon" type="button" onClick={handleSearchClick}>
                                            <SearchIcon sx={{ fontSize: 25, color: theme.palette.icon.main }} />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            )}
                            
                        </Box>
                        <Tooltip title={t('NavBar.UserSettings')?.toString()}>
                            <IconButton
                                type="button"
                                id="user-settings-button"
                                onClick={handleUserSettingsClick}
                                aria-controls="user-settings-menu"
                                aria-haspopup="true"
                                aria-expanded={openUserSettings ? 'true' : undefined}>
                                <Avatar alt="" src={session?.avatar?.URI} sx={{ bgcolor: session?.avatar?.color }}>
                                    {session?.avatar?.letter}
                                </Avatar>
                            </IconButton>
                        </Tooltip>

                        <Menu
                            id="user-settings-menu"
                            anchorEl={usersettingsAnchorEl}
                            open={openUserMenu}
                            onClose={handleUserSettingsClose}
                            onClick={handleUserSettingsClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}>
                            <MenuItem onClick={() => userSettingsToggle()}>
                                <Stack>
                                    {!isGuest() && <Typography sx={{ fontSize: '1rem', color: grey[700] }}>{session?.email}</Typography>}
                                    <Typography>{capitalizeFirstLetter(session?.name)}</Typography>
                                </Stack>
                            </MenuItem>
                            <Divider />
                            {!isGuest() && <MenuItem onClick={() => userSettingsToggle()}>{t('NavBar.UserSettings')}</MenuItem>}
                            <Divider />
                            {!isGuest() ? (
                                <MenuItem onClick={() => logoutUI()}>{t('NavBar.SignOut')}</MenuItem>
                            ) : (
                                <MenuItem onClick={() => navigate('/login')}>{t('NavBar.Login')}</MenuItem>
                            )}
                        </Menu>
                    </Stack>
                </Stack>
            </AppBar>
            <UserSettings open={openUserSettings} close={closeUserSettings} />
        </Box>
    )
}
